import React, { useRef, useEffect } from 'react';
import './App.css';
import io from "socket.io-client";

function Mapa7(username) {
  let vista3 = useRef(null);
  useEffect(() => {
    const socket_url = process.env.REACT_APP_CONECT_SOCKET_PRO;
    const socket = io(socket_url, {
      transports: ["websocket"]
    });
    socket.on('connect', () => {
      socket.emit('enlace_azul', username.username);
    });
    socket.on('datos', (data) => {
      const ch1 = document.getElementById("ch1");
      const ch2 = document.getElementById("ch2");
      const ch3 = document.getElementById("ch3");
      const ch4 = document.getElementById("ch4");
      const ch5 = document.getElementById("ch5");
      const ch6 = document.getElementById("ch6");
      const ch7 = document.getElementById("ch7");
      const ch8 = document.getElementById("ch8");

      if (ch2 && ch3 && ch4 && ch5 && ch6 && ch7 && ch8) {
        document.getElementById("ch2").innerHTML = data.distancia6?.temp;
        let d6 = Math.round(Math.pow(10, ((-59 - (data.distancia6?.rssi)) / (10 * 2.7))) * 100) / 100;;
        if (data.distancia6?.rssi === undefined) {
          document.getElementById("ch3").innerHTML = 'No data';
        } else {
          document.getElementById("ch3").innerHTML = d6
        }
        document.getElementById("ch4").innerHTML = data.distancia6?.time;
        if (data.distancia6?.humidty === undefined) {
          document.getElementById("ch1").innerHTML = '-';
        } else {
          document.getElementById("ch1").innerHTML = data.distancia6?.humidty;
        }
        if (data.distancia6?.pir === undefined) {
          document.getElementById("ch5").innerHTML = '-';
        } else {
          document.getElementById("ch5").innerHTML = data.distancia6?.pir;
        }
        if (data.distancia6?.lux === undefined) {
          document.getElementById("ch6").innerHTML = '-';
        } else {
          document.getElementById("ch6").innerHTML = data.distancia6?.lux;
        }
        if (data.distancia6?.cutoff === undefined) {
          document.getElementById("ch7").innerHTML = '-';
        } else {
          document.getElementById("ch7").innerHTML = data.distancia6?.cutoff;
        }
        if (data.distancia6?.status === undefined) {
          document.getElementById("ch8").innerHTML = 'Desconectada';
        } else {
          document.getElementById("ch8").innerHTML = data.distancia6?.status;
        }
        // if (isNaN(d6) && vista3.current) {
        //   vista3.current.style.display = 'none';
        // } else if (vista3.current) {
        //   vista3.current.style.display = '';
        // }
      }
    });
  }, []);
  return (
    <div>
      <table ref={vista3} border="1">
        <thead>
          <tr>
            <th scope="col">Ubicación</th>
            <th scope="col">Temp (grados)</th>
            <th scope="col">Humedad (%)</th>
            <th scope="col">Pir (Bool)</th>
            <th scope="col">Luz (Vat)</th>
            <th scope="col">cutoff (Vat)</th>
            <th scope="col">Fecha</th>
            <th scope="col">Distancia (m)</th>
            <th scope="col">Estado (Bool)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Sala: </td>
            <td id="ch2" data-sortable="true"></td>
            <td id="ch1" data-sortable="true"></td>
            <td id="ch5" data-sortable="true"></td>
            <td id="ch6" data-sortable="true"></td>
            <td id="ch7" data-sortable="true"></td>
            <td id="ch4" data-sortable="true"></td>
            <td id="ch3" data-sortable="true"></td>
            <td id="ch8" data-sortable="true"></td>
          </tr>
        </tbody>
      </table>
    </div>

  );
}
export default Mapa7;