import React, { useRef, useEffect } from 'react';
import './App.css';
import io from "socket.io-client";

function Mapa5(username) {
  let vista3 = useRef(null);
  useEffect(() => {
    const socket_url = process.env.REACT_APP_CONECT_SOCKET_PRO;
    const socket = io(socket_url, {
      transports: ["websocket"]
    });
    socket.on('connect', () => {
      socket.emit('enlace_azul', username.username);
    });
    socket.on('datos', (data) => {
      const ct1 = document.getElementById("ct1");
      const ct2 = document.getElementById("ct2");
      const ct3 = document.getElementById("ct3");
      const ct4 = document.getElementById("ct4");
      const ct5 = document.getElementById("ct5");
      const ct6 = document.getElementById("ct6");
      const ct7 = document.getElementById("ct7");
      const ct8 = document.getElementById("ct8");

      if (ct2 && ct3 && ct4 && ct5 && ct6 && ct7 && ct8) {
        document.getElementById("ct2").innerHTML = data.distancia7?.temp;
        let d7 = Math.round(Math.pow(10, ((-59 - (data.distancia7?.rssi)) / (10 * 2.7))) * 100) / 100;;
        document.getElementById("ct3").innerHTML = d7;
        document.getElementById("ct4").innerHTML = data.distancia7?.time;
        if (data.distancia7?.humidty === undefined) {
          document.getElementById("ct1").innerHTML = '-';
        } else {
          document.getElementById("ct1").innerHTML = data.distancia7?.humidty;
        }
        if (data.distancia7?.pir === undefined) {
          document.getElementById("ct5").innerHTML = '-';
        } else {
          document.getElementById("ct5").innerHTML = data.distancia7?.pir;
        }
        if (data.distancia7?.lux === undefined) {
          document.getElementById("ct6").innerHTML = '-';
        } else {
          document.getElementById("ct6").innerHTML = data.distancia7?.lux;
        }
        if (data.distancia7?.cutoff === undefined) {
          document.getElementById("ct7").innerHTML = '-';
        } else {
          document.getElementById("ct7").innerHTML = data.distancia7?.cutoff;
        }
        if (data.distancia7?.status === undefined) {
          document.getElementById("ct8").innerHTML = 'Desconectada';
        } else {
          document.getElementById("ct8").innerHTML = data.distancia7?.status;
        }
        // if (isNaN(d7) && vista3.current) {
        //   vista3.current.style.display = 'none';
        // } else if (vista3.current) {
        //   vista3.current.style.display = '';
        // }
      }
    });
  }, []);
  return (
    <div>
      <table ref={vista3} border="1">
        <thead>
          <tr>
            <th scope="col">Ubicación</th>
            <th scope="col">Temp (grados)</th>
            <th scope="col">Humedad (%)</th>
            <th scope="col">Pir (Bool)</th>
            <th scope="col">Luz (Vat)</th>
            <th scope="col">cutoff (Vat)</th>
            <th scope="col">Fecha</th>
            <th scope="col">Distancia (m)</th>
            <th scope="col">Estado (Bool)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Cocina: </td>
            <td id="ct2" data-sortable="true"></td>
            <td id="ct1" data-sortable="true"></td>
            <td id="ct5" data-sortable="true"></td>
            <td id="ct6" data-sortable="true"></td>
            <td id="ct7" data-sortable="true"></td>
            <td id="ct4" data-sortable="true"></td>
            <td id="ct3" data-sortable="true"></td>
            <td id="ct8" data-sortable="true"></td>
          </tr>
        </tbody>
      </table>
    </div>

  );
}
export default Mapa5;