import React, { useState } from 'react';
import axios from 'axios';
import './App.css';
import MyComponent from './barra'
import Mapa5 from './mapa5'
import Mapa6 from './mapa6'
import Mapa7 from './mapa7'
import Mapa8 from './mapa8'
import Pie from './pieMasterdent.js';
import MyComponent1 from './mapeo'
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
Amplify.configure(awsExports);


function Scan_c2({ user }) {
  const username = user.username;
  const [nombre, setNombre] = useState('');
  const backend = process.env.REACT_APP_POST_BACKEND_PRO_1;
  function parar(){
    const data = '';
    axios.post(backend, data)
      .then(response => this?.setState({ articleId: response?.data }))
      .catch(error => {
        this?.setState({ errorMessage: error?.message });
        console.error('There was an error!', error);
      });
    axios.get(backend)
      .then(response => this.setState({ response }));
  }
  function reload() {
    window.location.reload();
  }
  const handleSubmit = (event) => {

    event.preventDefault();
    let data = {
      nombre: nombre,
      usuario: username,
    };
    alert('Bien hecho!!')
    axios.post(backend, data)
      .then(response => this?.setState({ articleId: response?.data }))
      .catch(error => {
        this?.setState({ errorMessage: error?.message });
        console.error('There was an error!', error);
      });
    axios.get(backend)
      .then(response => this.setState({ response }));
  };

  return (
    <div>
      <MyComponent />
      <div id="title">
        <div class="row row-sm-w-100 py-3 text-center text-primary font-weight-bold">
          <h1>Enlace azul - Demo</h1>
        </div>
      </div>
      <div class="container">
        <div class="row row-cols-2">
          <div class="col">
            <div id="seleccioneElDispositivo" class="mt-3">
              <div class="row ">
                <h3 class="text-center text-primary h3 ">Seleccione el dispositivo</h3>
              </div>
              <div class="row container-fluid justify-content-between">
                <div class="col-9">
                  <form onSubmit={handleSubmit}>
                    <select class="form-select" value={nombre} onChange={(e) => setNombre(e.target.value)}>
                      <option selected class="negrita" >Dispositivos</option>
                      <option id="b1" value="BC572903F536">Baliza de corto alcance</option>
                      <option id="b5" value="BC5729004DB8">Baliza de largo alcance</option>
                      <option id="b2" value="BC57290E2795">Tarjeta</option>
                      <option id="b2" value="BC5729121F7B">Detector de movimiento</option>
                    </select>
                  </form>
                </div>
                <div class="col-3 ">
                  <button class="btn btn-outline-primary " data-name="baliza1" id="ver1" onClick={handleSubmit}>Escanear</button>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <Pie username={username} />
          </div>
          <div class="col">
            <div id="title">
              <div class="row row-sm-w-100 py-3 text-center text-primary font-weight-bold">
                {/* <Mapa5 username={username} />
                <Mapa6 username={username} /> */}
                <Mapa7 username={username} />
                <Mapa8 username={username} />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="col-6">
              <h3 class="text-center text-primary h3 ">Ubicación</h3>
              <br></br>
              <MyComponent1 username={username} />
            </div>
          </div>
        </div>
      </div>
      <div id="bloqueUno" class="w-75 mx-auto">
        <div class="row row-lg-w-75 row-sm-w-100 row-sm-d-block justify-content-between">
          <div class="col-sm-w-100 col-md-6">
            <div id="select" class=" my-1">
              <div id="botones" class=" botones-md-w-100 ">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Scan_c2;