import React, { useState, useEffect } from 'react';
import io from "socket.io-client";
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
import { PieChart } from '@mui/x-charts/PieChart';
Amplify.configure(awsExports);

function Pie(username) {
  const socket_url = process.env.REACT_APP_CONECT_SOCKET;
  const [d1, setD1] = useState(0); 
  const [d2, setD2] = useState(0); 

  useEffect(() => {
    const socket = io(socket_url, {
      transports: ["websocket"]
    });
    socket.on('connect', () => {
      socket.emit('enlace_azul', username.username);
    });
    socket.on('datos', (data) => {
      const d6 = Math.round(Math.pow(10, (-(-59 - (data.distancia6?.rssi)) / (10 * 2.7))) * 100) / 100;
      const d7 = Math.round(Math.pow(10, (-(-59 - (data.distancia7?.rssi)) / (10 * 2.7))) * 100) / 100;
      setD1(d6);
      setD2(d7);
    });
    return () => {
      socket.disconnect();
    };
  }, [setD1, setD2]); 

  return (
    <div>
      <PieChart
        series={[{
          data: [
            { id: 0, value: d1, label: 'Sala' },
            { id: 1, value: d2, label: 'Cocina' }
          ]
        }]}
        width={400}
        height={200}
      />
    </div>
  );
}

export default Pie;
