import Iframe from 'react-iframe';
import { withAuthenticator } from '@aws-amplify/ui-react';
import './App.css';
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
import MyComponent from './barra'
import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
Amplify.configure(awsExports);


function Historico() {
  const [user, setUser] = useState('');
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await Auth.currentAuthenticatedUser();
        setUser(userData);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    }
    fetchUserData();
  },[]);
    console.log(user.username)
    if(user.username === 'laura'){
    return (
      <div>
          <MyComponent/>
          <div id="titulo">
    <div class="row row-sm-w-100 py-3 text-center text-primary font-weight-bold">
      <div class="row">
        <h1 class="text-center " id="h">Análisis de datos Soma</h1>
        <Iframe
        width="100%"
        height="500px"
        src="https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/118717860130/dashboards/bc690de7-9fdf-42ef-a4ef-2864b1b269be?directory_alias=johnatan">
    </Iframe>
      </div>
    </div>
  </div>
  <div id="Nosotros">
    <div class="container-md p-4">
      <div class="row">   
      </div>
    </div>
  </div>
      </div>
    );
  }
  if(user.username === 'johnatan'){
    return (
      <div>
          <MyComponent/>
          <div id="titulo">
    <div class="row row-sm-w-100 py-3 text-center text-primary font-weight-bold">
      <div class="row">
        <h1 class="text-center " id="h">Análisis de datos</h1>  
    <Iframe
        width="100%"
        height="500px"
        src="https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/118717860130/dashboards/e51831d7-57b9-4059-b592-06985658f5da?directory_alias=johnatan">
    </Iframe>
      </div>
    </div>
  </div>
  <div id="Nosotros">
    <div class="container-md p-4">
      <div class="row">   
      </div>
    </div>
  </div>
      </div>
    );
  }
  }
  export default withAuthenticator(Historico);