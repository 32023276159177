import React, { useState, useEffect } from 'react';
import io from "socket.io-client";
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
import { PieChart } from '@mui/x-charts/PieChart';
Amplify.configure(awsExports);

function PieVigilancia(username) {
  const socket_url = process.env.REACT_APP_CONECT_SOCKET;
  const [d, setD] = useState(0); 
  const [d1, setD1] = useState(0); 
  const [d2, setD2] = useState(0); 
  const [d3, setD3] = useState(0); 

  useEffect(() => {
    const socket = io(socket_url, {
      transports: ["websocket"]
    });
    socket.on('connect', () => {
      socket.emit('enlace_azul', username.username);
    });
    socket.on('datos', (data) => {
      const d = Math.round(Math.pow(10, (-(-59 - (data.distancia?.rssi)) / (10 * 2.7))) * 100) / 100;
      const d1 = Math.round(Math.pow(10, (-(-59 - (data.distancia1?.rssi)) / (10 * 2.7))) * 100) / 100;
      const d2 = Math.round(Math.pow(10, (-(-59 - (data.distancia2?.rssi)) / (10 * 2.7))) * 100) / 100;
      const d3 = Math.round(Math.pow(10, (-(-59 - (data.distancia3?.rssi)) / (10 * 2.7))) * 100) / 100;
      setD(d);
      setD1(d1);
      setD2(d2);
      setD3(d3);
    });
    return () => {
      socket.disconnect();
    };
  }, [setD, setD1,setD2,setD3]); 

  return (
    <div>
      <PieChart
        series={[{
          data: [
            { id: 0, value: d, label: 'Admin' },
            { id: 1, value: d1, label: 'Monitoreo' },
            { id: 2, value: d2, label: 'Area técnica' },
            { id: 3, value: d3, label: 'Por definir' }
          ]
        }]}
        width={400}
        height={200}
      />
    </div>
  );
}

export default PieVigilancia;
