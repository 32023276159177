import React, { useRef, useEffect } from 'react';
import './App.css';
import io from "socket.io-client";

function Mapa4(username) {
  let vista3 = useRef(null);
  useEffect(() => {
    const socket_url = process.env.REACT_APP_CONECT_SOCKET_PRO;
    const socket = io(socket_url, {
      transports: ["websocket"]
    });
    socket.on('connect', () => {
      socket.emit('enlace_azul', username.username);
    });
    socket.on('datos', (data) => {
      const cc1 = document.getElementById("cc1");
      const cc2 = document.getElementById("cc2");
      const cc3 = document.getElementById("cc3");
      const cc4 = document.getElementById("cc4");
      const cc5 = document.getElementById("cc5");
      const cc6 = document.getElementById("cc5");

      if (cc2 && cc3 && cc4 && cc5) {
        document.getElementById("cc2").innerHTML = data.distancia3?.temp;
        let d3 = Math.round(Math.pow(10, ((-59 - (data.distancia3?.rssi)) / (10 * 2.7))) * 100) / 100;;
        document.getElementById("cc3").innerHTML = d3
        document.getElementById("cc4").innerHTML = data.distancia3?.time;
        if (data.distancia3?.humidty === undefined) {
          document.getElementById("cc1").innerHTML = '-';
        } else {
          document.getElementById("cc1").innerHTML = data.distancia3?.humidty;
        }
        if (data.distancia3?.rssi === undefined) {
          document.getElementById("cc5").innerHTML = '-';
        } else {
          document.getElementById("cc5").innerHTML = data.distancia3?.rssi;
        }
        if (data.distancia3?.status === undefined) {
          document.getElementById("cc6").innerHTML = 'Desconectada';
        } else {
          document.getElementById("cc6").innerHTML = data.distancia3?.status;
        }
        // if (isNaN(d3) && vista3.current) {
        //   vista3.current.style.display = 'none';
        // } else if (vista3.current) {
        //   vista3.current.style.display = '';
        // }
      }
    });
  }, []);
  return (
    <div>
      <table ref={vista3} border="1">
        <thead>
          <tr>
            <th scope="col">Ubicación</th>
            <th scope="col">Temp (grados)</th>
            <th scope="col">Humedad (%)</th>
            <th scope="col">Fecha</th>
            <th scope="col">RSSI (dB)</th>
            <th scope="col">Distancia (m)</th>
            <th scope="col">Estado (Bool)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Por definir: </td>
            <td id="cc2" data-sortable="true"></td>
            <td id="cc1" data-sortable="true"></td>
            <td id="cc4" data-sortable="true"></td>
            <td id="cc5" data-sortable="true"></td>
            <td id="cc3" data-sortable="true"></td>
            <td id="cc6" data-sortable="true"></td>
          </tr>
        </tbody>
      </table>
    </div>

  );
}
export default Mapa4;