import React from 'react';
import { Navbar, Nav, Offcanvas } from 'react-bootstrap';
import { withAuthenticator } from '@aws-amplify/ui-react';
import './App.css';
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSearch, faHistory, faTools, faMapMarked } from '@fortawesome/free-solid-svg-icons';
Amplify.configure(awsExports);

function Barra({ signOut, user }) {
  if (user.username === 'johnatan') {
    return (
      <div>
        <Navbar bg="light" expand={false} className='celeste'>
          <Navbar.Brand href="#"><img src="../Logo.bmp" alt="Logo" width="100" /></Navbar.Brand>
          <Navbar.Toggle aria-controls="offcanvasNavbar" />
          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="start"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel">Menú</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav.Link href="/inicio"><FontAwesomeIcon icon={faHome} /> Inicio</Nav.Link>
                <Nav.Link href="/scan"><FontAwesomeIcon icon={faSearch} /> Localizar un activo</Nav.Link>
                <Nav.Link href="/"><FontAwesomeIcon icon={faMapMarked} /> Ver mapa</Nav.Link>
                {/* <Nav.Link href="/filtros">Áreas</Nav.Link> */}
                <Nav.Link href="/historico"><FontAwesomeIcon icon={faHistory} /> Histórico</Nav.Link>
                <Nav.Link href="/soporte"><FontAwesomeIcon icon={faTools} /> Estado de hadware</Nav.Link>
                <button class="btn btn-outline-dark" onClick={signOut}>Salir</button>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Navbar>

      </div>
    );
  }
  if (user.username === 'laura') {
    return (
      <div>
        <Navbar bg="light" expand={false} className='celeste'>
          <Navbar.Brand href="#"><img src="../Logo.bmp" alt="Logo" width="100" /></Navbar.Brand>
          <Navbar.Toggle aria-controls="offcanvasNavbar" />
          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="start"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel">Menú</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav.Link href="/inicio"><FontAwesomeIcon icon={faHome} /> Inicio</Nav.Link>
                <Nav.Link href="/scan_c2"><FontAwesomeIcon icon={faSearch} /> Localizar un activo</Nav.Link>
                <Nav.Link href="/"><FontAwesomeIcon icon={faMapMarked} /> Ver mapa</Nav.Link>
                {/* <Nav.Link href="/filtros">Áreas</Nav.Link> */}
                <Nav.Link href="/historico"><FontAwesomeIcon icon={faHistory} /> Histórico</Nav.Link>
                <Nav.Link href="/soporte"><FontAwesomeIcon icon={faTools} /> Estado de hadware</Nav.Link>
                <button class="btn btn-outline-dark" onClick={signOut}>Salir</button>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Navbar>
      </div>
    );

  }
}
export default withAuthenticator(Barra);